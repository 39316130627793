import classes from './Contact.module.scss';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Versturen...");
    const response = await fetch(`/.netlify/functions/${process.env.REACT_APP_FUNCTION_EMAIL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setStatus("E-mail succesvol verzonden!");
      setFormData({ name: "", email: "", message: "" });
      success();
    } else {
      setStatus("Fout bij verzenden e-mail.");
    }
  };

  const history = useHistory();
  const success = () => history.push("/success");

  return (
    <div className={classes.Contact}>
      <div className="container">
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <h1 className={classes.contentTitle}>Let's talk!</h1>
            <p className={classes.paragraph}>
              Wilt u graag meer informatie over de mogelijkheden. Stuur een
              bericht en dan wordt er zo snel mogelijk contact met u opgenomen.
            </p>

            <div className={classes.contact}>
              <h3>TDR Photography</h3>
              <div className={classes.contactInfo}>
                <div className={classes.address}>Barentszrede 2</div>
                <div className={classes.address}>2725 GE Zoetermeer</div>
                <div className={classes.phone}>0646618315</div>
                <div className={classes.email}>info@tdrphotography.nl</div>
              </div>
            </div>
          </div>

          <div className={classes.formWrapper}>
            <form method="POST" onSubmit={handleSubmit}>
              <input type="text" name="name" placeholder="Naam*" value={formData.name} onChange={handleChange} required />
              <input type="email" name="email" placeholder="Email*"  value={formData.email} onChange={handleChange} required />
              <input type="number" name="phone" placeholder="Telefoonnummer*" value={formData.phone} onChange={handleChange} required/>
              <textarea
                name="message"
                cols="30"
                rows="0"
                placeholder="Bericht*"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>

              <input type="submit" value="Verstuur" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
